<template>
  <div>
    <vs-popup class="sm:popup-w-80" title="Edit Customer Prospek" :active="isActive" v-on:update:active="emitModalIsActive">

      <div>
        <div class="vx-row">
          <div class="vx-col w-full">
            <ValidationErrors :errors="errors"/>
          </div>
        </div>

        <div class="vx-row mb-3">
          <div class="vx-col sm:w-1/2 w-full">
            <label class="ml-1 text-xs">Proyek</label>
            <v-select :options="proyeks" label="nama" :reduce="item => item.id" :clearable="false" v-model="data.id_proyek"/>
          </div>
          <div class="vx-col sm:w-1/2 w-full">
            <label class="ml-1 text-xs">Staf Marketing *</label>
            <vx-input-group>
              <vs-input :value="data.nama_staf" readonly @click="isModalStafActive = true"/>
              <template slot="append">
                <div class="append-text btn-addon">
                  <vs-button type="filled" icon-pack="feather" icon="icon-search" @click="isModalStafActive = true"/>
                </div>
              </template>
            </vx-input-group>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-3/12 w-full">
            <label class="ml-1 text-xs">Kode Prospek *</label>
            <vs-input class="w-full" v-model="data.kode" placeholder="Generate Otomatis" disabled/>
          </div>
          <div class="vx-col sm:w-3/12 w-full">
            <label class="ml-1 text-xs">Tgl. Prospek *</label>
            <vx-input-group>
              <flat-pickr class="w-full" v-model="data.tgl"></flat-pickr>
              <template slot="append">
                <div class="append-text btn-addon">
                  <vs-button type="filled" icon-pack="feather" icon="icon-calendar"/>
                </div>
              </template>
            </vx-input-group>
          </div>
          <div class="vx-col sm:w-3/12 w-full">
            <label class="ml-1 text-xs">Status *</label>
            <vs-input class="w-full" v-model="data.status" disabled/>
          </div>
          <div class="vx-col sm:w-3/12 w-full">
            <label class="ml-1 text-xs">Sumber Info *</label>
            <v-select :options="sumberInfos" label="nama" :reduce="item => item.id" :clearable="false" v-model="data.id_sumber_info"/>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-1/1 w-full">
            <label class="ml-1 text-xs">Keterangan *</label>
            <vs-textarea class="w-full" icon-pack="feather" icon="icon-map-pin" v-model="data.keterangan"/>
          </div>
        </div>

        <div class="vx-row mb-3"></div>
        <vs-divider> Detail Prospek Customer </vs-divider>

        <div class="vx-row mb-3">
          <div class="vx-col sm:w-4/12 w-full">
            <vs-input class="w-full" label="Kode Customer *" v-model="data.customer.kode" readonly/>
          </div>
          <div class="vx-col sm:w-4/12 w-full">
            <vs-input class="w-full" icon-pack="feather" icon="icon-user" label="Nama *" v-model="data.customer.nama"/>
          </div>
          <div class="vx-col sm:w-4/12 w-full">
            <vs-input class="w-full" icon-pack="feather" icon="icon-smartphone" label="HP *" v-model="data.customer.hp"/>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-4/12 w-full">
            <label class="ml-1 text-xs">Profesi</label>
            <v-select :options="profesis" label="nama" :reduce="item => item.id" :clearable="false" v-model="data.customer.id_profesi"/>
          </div>
          <div class="vx-col sm:w-4/12 w-full">
            <label class="ml-1 text-xs">Jenis Kelamin</label>
            <v-select :options="[{gender: 'L', nama: 'Laki-Laki'}, {gender: 'P', nama: 'Perempuan'}]" label="nama" :reduce="item => item.gender" :clearable="false" v-model="data.customer.gender"/>
          </div>
          <div class="vx-col sm:w-4/12 w-full">
            <vs-input class="w-full" icon-pack="feather" icon="icon-mail" label="E-mail" v-model="data.customer.email"/>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-4/12 w-full">
            <vs-input class="w-full" label="Kota *" v-model="data.customer.kota"/>
          </div>
          <div class="vx-col sm:w-8/12 w-full">
            <vs-input class="w-full" icon-pack="feather" icon="icon-map-pin" label="Alamat" v-model="data.customer.alamat"/>
          </div>
        </div>

        <div class="vx-row float-right mt-6">
          <div class="vx-col w-full">
            <vs-button class="mr-3" type="border" @click="emitModalIsActive(false)">Batal</vs-button>
            <vs-button :disabled="isLoading" @click="save">
              <span v-if="isLoading" class="animate-pulse">Menyimpan...</span>
              <span v-if="!isLoading">Simpan</span>
            </vs-button>
          </div>
        </div>
      </div>

      <!--modal staf-->
      <vs-popup class="sm:popup-w-70 popup-content-no-padding"
                title="Pilih Data Staf"
                :active="isModalStafActive"
                v-on:update:active="isModalStafActive = $event">
        <Staf :selectable="true"
              :externalFilter="filterStaf"
              @selected="onSelectedModalStaf"/>
      </vs-popup>

    </vs-popup>
  </div>
</template>

<script>
import CustomerProspekRepository from '@/repositories/marketing/customer-prospek-repository'
import ProyekRepository from '@/repositories/master/proyek-repository'
import Staf from '@/views/pages/master/staf/Staf'
import ValidationErrors from '@/views/components/validation-errors/ValidationErrors'
import 'flatpickr/dist/flatpickr.css'
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'
import _ from 'lodash'
import ProfesiRepository from '@/repositories/master/profesi-repository'
import SumberInfoRepository from '@/repositories/master/sumber-info-repository'

export default {
  name: 'CustomerProspekEdit',
  props: ['isActive', 'item'],
  components: {
    Staf,
    flatPickr,
    ValidationErrors,
    'v-select': vSelect
  },
  computed: {
    filterStaf () {
      return { id_proyek: this.data.id_proyek }
    }
  },
  watch: {
    isActive (value) {
      if (value === true) {
        this.data = Object.assign({}, this.item)

        if (this.isFirstActive) {
          this.getAllProyek()
          this.getAllProfesi()
          this.getAllSumberInfo()
          this.isFirstActive = false
        }
      }
    }
  },
  data () {
    return {
      isFirstActive: true,
      isLoading: false,
      errors: null,
      isModalStafActive: false,
      isModalCustomerActive: false,
      data: {
        customer: {
          gender: 'L'
        },
        id_proyek: null
      },
      proyeks: [],
      profesis: [],
      sumberInfos: []
    }
  },
  methods: {
    onSelectedModalStaf (item) {
      this.data.id_staf = item.id
      this.data.nama_staf = item.nama
      this.isModalStafActive = false
    },

    getAllProyek () {
      const params = {
        filter: JSON.stringify({
          only_authorized_user: 1
        })
      }
      ProyekRepository.getAll(params)
        .then(response => {
          this.proyeks = response.data.data
        })
        .catch(error => {
          console.log(error)
        })
    },

    getAllProfesi () {
      ProfesiRepository.getAllProfesi()
        .then(response => {
          this.profesis = response.data.data
        })
        .catch(error => {
          console.log(error)
        })
    },

    getAllSumberInfo () {
      SumberInfoRepository.getAllDistinct()
        .then(response => {
          this.sumberInfos = response.data.data
        })
        .catch(error => {
          console.log(error)
        })
    },

    save () {
      this.errors = null
      this.isLoading = true

      CustomerProspekRepository.update(this.data)
        .then(response => {
          this.onSuccess()
        })
        .catch(error => {
          console.log(error)
          if (error.response.status === 422) {
            this.errors = error.response.data.errors
          } else {
            this.notifyError('Terjadi kesalahan.')
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    onSuccess () {
      this.notifySuccess('Data berhasil disimpan.')
      this.resetData()
      this.emitIsSuccess(true)
      this.emitModalIsActive(false)
    },

    resetData () {
      const exceptObjects = _.pick(this.$data, ['proyeks'])
      const newData = Object.assign(this.$options.data.call(this), exceptObjects)
      Object.assign(this.$data, newData)
    },

    emitIsSuccess (isSuccess) {
      this.$emit('success', isSuccess)
    },

    emitModalIsActive (isActive) {
      this.$emit('update:isActive', isActive)
    }
  }
}
</script>
